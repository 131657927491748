<template>
  <Card title="签署规则">
    <div class="rule-card">
      <div class="rule-item">
        <div class="rule-name">标题</div>
        <div class="rule-value">
          <input
            class="title-input"
            placeholder="请输入签署标题"
            v-model="templateData.titleName"
            type="text"
            maxlength="20"
          />
        </div>
      </div>
      <div class="rule-item">
        <div class="rule-name">签署有效期（天）</div>
        <div class="rule-value">
          <div class="valid-day">
            <div class="valid-day-value">
              <span
                class="operation-icon decrement"
                @click="dayChange('minus')"
              >
                <img src="@/assets/imgs/signFile/形状.svg" alt="" />
              </span>

              <van-field
                class="operation-value"
                v-model.trim.number="templateData.validDay"
                type="digit"
                input-align="center"
                @input="() => {}"
              />
              <span class="operation-icon increment" @click="dayChange('add')">
                <img src="@/assets/imgs/signFile/加.svg" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="rule-item">
        <div class="rule-name">通知方式</div>
        <div class="rule-value">
          <van-checkbox-group
            v-model="templateData.noticeWay"
            class="rules-radio-group"
            @change="noticChange"
          >
            <van-checkbox shape="square" :name="0" icon-size="1.1em">
              站内信
            </van-checkbox>
            <van-checkbox
              shape="square"
              :name="1"
              icon-size="1.1em"
              class="last"
            >
              短信/邮件
            </van-checkbox>
          </van-checkbox-group>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
  import { mapState, mapMutations } from 'vuex'
  import Card from './Card.vue'

  export default {
    name: 'rulesCard',
    components: {
      Card,
    },
    data() {
      return {
        noticeWay: [0],
      }
    },
    computed: {
      ...mapState('publishModel', {
        templateData: state => state.templateData,
        partnerInfo: state => state.partnerInfo,
      }),
    },
    mounted() {},
    methods: {
      ...mapMutations('publishModel', {
        setTemplateData: 'setTemplateData',
      }),
      dayChange(type) {
        if (type === 'add') {
          this.setTemplateData({
            validDay: ++this.templateData.validDay,
          })
        } else {
          if (this.templateData.validDay <= 0) return
          this.setTemplateData({
            validDay: --this.templateData.validDay,
          })
        }
      },

      noticChange(val) {
        const filter = this.partnerInfo.filter(item => item.partnerRole)
        filter.forEach(item => {
          item.noticeWay = val
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .rule-card {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    .rule-item {
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      padding: 2px 0;
      box-sizing: border-box;
      .rule-name {
        width: 140px;
        font-size: 14px;
      }
      .rule-value {
        display: flex;
        .valid-day {
          display: flex;
          align-content: center;

          .valid-day-value {
            font-size: 14px;
            width: 114px;
            height: 30px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #8a9ab4;
            border-radius: 2px;
            box-sizing: border-box;
            .van-hairline--right {
              border-right: 0.5px solid #8a9ab4;
              height: 100%;
            }
            .van-hairline--left {
              border-left: 0.5px solid #8a9ab4;
              height: 100%;
            }
            .operation-icon {
              font-size: 24px;
              color: #8a9ab4;
              width: 30px;
              height: 100%;
              display: inline-block;
              text-align: center;
              line-height: 100%;
            }

            .operation-value {
              width: 53px;
              height: 27px;
              line-height: 27px;
              display: inline-block;
              text-align: center;
              color: #111a34;
              padding: 0;
            }
            .decrement {
              border-right: 0.5px solid #8a9ab4;
            }
            .increment {
              border-left: 0.5px solid #8a9ab4;
            }
          }
        }

        .switch {
          /deep/ .van-checkbox__icon {
            font-size: 18px;
          }
        }
        input {
          width: 270px;
        }
      }
    }

    .title-input {
      width: 100%;
      border: none;
      font-size: 14px;
      color: @TEXT-COLOR-0;
      padding: 14px 0;
      text-align: right;
    }

    .rules-radio-group {
      display: flex;
      align-items: center;
      font-size: 14px;

      .last {
        margin-left: 14px;
      }
    }
  }
</style>
