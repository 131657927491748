<template>
  <div class="publish-card">
    <div class="title-wrap">
      <div class="title">{{ title }}</div>
      <slot name="title"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    components: {},
    props: ['title'],
    data() {
      return {}
    },
    mounted() {},
    methods: {},
  }
</script>

<style lang="less" scoped>
  .publish-card {
    padding: 16px;
    background: @WHITE;
    margin-bottom: 14px;

    .title-wrap {
      border-bottom: 1px solid #eeeeee;
      margin: 0 -16px;
      padding: 0 16px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: #111a34;
      .title {
        font-size: 18px;
      }
    }
  }
</style>
