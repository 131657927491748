<template>
  <div class="process-card">
    <div class="process-title-wrap">
      <div class="tag">外部企业</div>
      <div class="tag tag-edit van-hairline--surround">
        {{ partener.defaultName }}
      </div>
      <!-- <van-icon name="edit" size="1.3em" /> -->

      <img
        class="delete-icon"
        src="@/assets/imgs/编组 17@2x.png"
        @click="delCard"
      />
    </div>
    <div class="content-wrap">
      <div class="rule-item">
        <div class="rule-name">企业名称</div>
        <div class="rule-value">
          <input
            class="title-input"
            placeholder="需与营业执照名称一致"
            type="text"
            v-model="partener.signerList[0].comName"
          />
          <van-icon class="addressBook" name="manager" @click="selectPerson" />
        </div>
      </div>

      <div class="rule-item">
        <div class="rule-name">经办人姓名</div>
        <div class="rule-value">
          <input
            class="title-input"
            placeholder="请输入真实姓名"
            type="text"
            v-model="partener.signerList[0].handlerName"
          />
        </div>
      </div>

      <div class="rule-item">
        <div class="rule-name">手机/邮箱</div>
        <div class="rule-value">
          <input
            class="title-input"
            placeholder="请输入手机/邮箱"
            type="text"
            v-model="partener.signerList[0].handlerTel"
          />
        </div>
      </div>
      <div class="handles-rule-item">
        <div class="rule-name">选择操作</div>
        <div class="handles-btn-wrap">
          <div
            class="button-item"
            :class="{ active: handleActives.includes(2) }"
            @click="handleChange(2)"
          >
            经办人签署
          </div>
          <div
            class="button-item"
            :class="{ active: handleActives.includes(3) }"
            @click="handleChange(3)"
          >
            法人签署
          </div>
          <div
            class="button-item"
            :class="{ active: handleActives.includes(4) }"
            @click="handleChange(4)"
          >
            企业签章
          </div>
        </div>
      </div>
      <PersonalFriend
        :show="addressBookShow"
        :type="2"
        append-to-body
        @onClose="addressBookShow = false"
        @roleCallback="personConfirm"
      />
    </div>
  </div>
</template>
<script>
  import PersonalFriend from '@/components/selectPerson/personalFriend.vue'

  /* 外部企业 */
  export default {
    name: 'Company',
    components: { PersonalFriend },
    props: {
      partener: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data() {
      return {
        /** 当前选中的操作方式 */
        handleActives: [],
        /** 通讯录展示隐藏 */
        addressBookShow: false,
      }
    },
    created() {
      /** 设置默认操作方式 */
      if (this.partener.partnerSubList[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.handleActives = this.partener.partnerSubList[0].operateList
      }
    },

    methods: {
      /** 改变操作方式: value: 2: 经办人签署,3: 法人签署, 4:企业签章 */
      async handleChange(value) {
        const isExist = this.handleActives.includes(value)
        if (isExist) {
          this.handleActives = this.handleActives.filter(item => item !== value)
        } else {
          this.handleActives.push(value)
        }
        this.$emit('change', {
          partnerSubList: [
            {
              fileIndex: '-1',
              fileKey: null,
              operateList: this.handleActives,
            },
          ],
        })
      },
      /** 打开通讯录 */
      selectPerson() {
        this.addressBookShow = true
      },

      personConfirm(val) {
        const temp = {
          signerList: [
            {
              comId: val.comId,
              comName: val.identity,
              handlerName: val.name,
              handlerTel: val.telephone,
              userId: val.uid,
            },
          ],
        }
        this.$emit('change', temp)
        this.addressBookShow = false
      },

      /** 删除 */
      delCard() {
        this.$emit('delCard', this.partener.guid)
      },
    },
  }
</script>

<style lang="less" scoped>
  .process-card {
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #f6f8fb;
    margin-top: 14px;

    .process-title-wrap {
      background: #eeeeee;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 12px;
      position: relative;

      .tag {
        color: #fff;
        font-size: 12px;
        background-color: #414c5c;
        border-radius: 2px;
        padding: 2px 5px;
        margin-right: 12px;
      }

      .tag-edit {
        background: transparent;
        color: #333333;
        border: 1px solid #c5c5c5;
      }

      .delete-icon {
        position: absolute;
        right: -1px;
        top: 0;
        width: 16px;
        height: 14px;
      }
    }

    .content-wrap {
      padding: 12px;
    }
  }
  .name {
    font-size: 16px;
    color: #111a34;
    font-weight: 500;
    padding: 7px 0;
  }

  .addressBook {
    font-size: 16px;
    color: #1676ff;
    padding-left: 6px;
  }
  .rule-item {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 2px 0;
    box-sizing: border-box;
    .rule-name {
      width: 90px;
      font-size: 14px;
    }
    .rule-value {
      display: flex;
      align-items: center;
      .valid-day {
        display: flex;
        align-content: center;
        .valid-day-value {
          font-size: 14px;
          width: 114px;
          height: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #8a9ab4;
          border-radius: 2px;
          box-sizing: border-box;
          .operation-icon {
            font-size: 24px;
            color: #8a9ab4;
            width: 30px;
            height: 100%;
            display: inline-block;
            text-align: center;
            line-height: 23px;
          }

          .operation-value {
            width: 53px;
            height: 27px;
            line-height: 27px;
            display: inline-block;
            text-align: center;
            color: #111a34;
            padding: 0;
          }
          .decrement {
            border-right: 0.5px solid #8a9ab4;
          }
          .increment {
            border-left: 0.5px solid #8a9ab4;
          }
        }
      }

      .switch {
        /deep/ .van-checkbox__icon {
          font-size: 18px;
        }
      }
    }
  }

  .title-input {
    width: 100%;
    border: none;
    font-size: 14px;
    color: @TEXT-COLOR-0;
    padding: 14px 0;
    text-align: right;

    &:disabled {
      background: transparent;
      color: #8c8c8c;
    }
  }

  .handles-rule-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    margin-top: 12px;
  }

  .handles-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 0 7px;
    .button-item {
      padding: 6px 10px;
      color: #333333;
      font-size: 12px;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      margin-right: 8px;
      position: relative;
    }
    .seal {
      position: relative;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .setting-icon {
      position: absolute;
      border: 1px solid #d5d5d5;
      padding: 6px 10px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
      top: -1px;
      bottom: -1px;
      right: -34px;
      line-height: 1.4em;
      border-color: #1676ff;
    }
    .active {
      border-color: #1676ff;
      color: #1676ff;
    }
  }
</style>
