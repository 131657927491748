<template>
  <div class="publish-template">
    <!-- 签署规则 -->
    <RulesCard />

    <!-- 签署文件 -->
    <FileCard />

    <!-- 参与方 -->
    <ProcessCard />

    <!-- 抄送 -->
    <MakeCopy />
    <div class="zhan"></div>

    <div class="bottom-button-wrap">
      <van-button type="info" block @click="handleLaunch">直接发起</van-button>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapGetters } from 'vuex'
  import publishApis from '@/api/publish'
  import contractBusinessApi from '@/api/contractBusiness'
  import { getRandom } from '@/utils/index'
  import RulesCard from './components/RulesCard.vue'
  import FileCard from './components/FileCard.vue'
  import ProcessCard from './components/ProcessCard.vue'
  import MakeCopy from './components/MakeCopy.vue'

  export default {
    components: {
      FileCard,
      RulesCard,
      ProcessCard,
      MakeCopy,
    },
    data() {
      return {}
    },
    created() {
      this.pageInit()
    },
    computed: {
      ...mapState('publishModel', {
        partnerInfo: state => state.partnerInfo,
        templateData: state => state.templateData,
      }),
      ...mapGetters('publishModel', ['isVariable']),
      templateId() {
        return this.$route.query.templateId
      },
    },
    methods: {
      ...mapMutations('publishModel', {
        setTemplateData: 'setTemplateData',
      }),
      async pageInit() {
        if (!this.templateId) return
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        try {
          const res = await publishApis.getTemplateFileDetail({
            templateId: this.templateId,
          })
          res.fileIndex = getRandom(13)

          this.setTemplateData({
            titleName: res.fileName,
            fileList: [res],
          })
        } catch (error) {
          console.error(error)
        } finally {
          this.$toast.clear()
        }
      },
      // 发起
      async handleLaunch() {
        this.$toast.loading({
          message: '提交中...',
          forbidClick: true,
          duration: 0,
        })
        const data = {
          ...this.templateData,
          partnerList: this.partnerInfo,
          noticeWay: 1,
          launchSign: this.isVariable ? 0 : 1, // 如果需要填写变量则存为草稿: 0-草稿 1-发起
          numStep: this.isVariable ? 2 : 3,
          step: 1,
        }

        const res = await contractBusinessApi.saveWithStatus(data)
        if (res.status !== 200) {
          this.$toast(res.msg)
          this.$toast.clear()
          return
        }
        this.$toast.clear()
        /** 跳转到变量填写页面 */
        this.$router.push({
            name: 'FillFile',
            query: {
              masterId: res.data.masterId,
            },
          })
      },
    },
  }
</script>

<style lang="less" scoped>
  .publish-template {
    background: #f4f5f6;
    overflow-x: hidden;
  }

  .zhan {
    height: calc(54px + constant(safe-area-inset-bottom));
    height: calc(54px + env(safe-area-inset-bottom));
  }

  .bottom-button-wrap {
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6px 12px;
    padding-bottom: calc(6px + constant(safe-area-inset-bottom));
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    z-index: 1;
  }
</style>
