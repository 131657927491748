<template>
  <div>
    <Card title="签署文件">
      <template slot="title">
        <span class="card-handle" @click="contractListShow = true">
          <van-icon
            name="plus"
            color="#1676ff"
            size="1.2em"
            style="font-weight: bold"
          />
          <span class="txt">添加模板</span>
        </span>
      </template>
      <div class="file-card">
        <FilesList
          v-for="(item, index) in templateData.fileList"
          :key="item.fileId"
          :name="item.fileName + item.fileFormat"
          :del-icon="index !== 0"
          :size="item.fileSize"
          :logo="item.logo"
          @deleteFile="() => deleteContrac(item.templateId)"
        />

        <!-- <div class="append">
          <div class="append-title-wrap">
            <div class="title">附件</div>
            <div class="upload">
              <input
                type="file"
                class="upload-file-handle"
                @change="fileUpload"
              />
              <van-icon
                name="plus"
                color="#1676ff"
                size="1.2em"
                style="font-weight: bold"
              /><span class="txt">上传附件</span>
            </div>
          </div>
        </div> -->
        <!-- <FilesList
          v-for="item in annexList"
          :key="item.radomId"
          :name="item.fileName"
          :size="item.fileSize"
          :del-icon="true"
          @deleteFile="() => deleteFile(item.radomId)"
        /> -->
      </div>
    </Card>

    <AsyncContractList
      :active="templateFileIds"
      :show.sync="contractListShow"
      :type="2"
      @confirm="contractConfirm"
      title="模版列表"
    />
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapState, mapMutations } from 'vuex'
  import { uploadFile, getFileData } from '@/model/launch'
  import { generateRandomKey } from '@/utils/helper'
  import publishApis from '@/api/publish'
  import { getRandom } from '@/utils/index'
  import Card from './Card.vue'
  import FilesList from '../../components/FilesList.vue'

  const AsyncContractList = Vue.component('AsyncContractList', () =>
    import('../../components/ContractList.vue')
  )

  export default {
    components: {
      Card,
      AsyncContractList,
      FilesList,
      // SelectFile,
    },
    data() {
      return {
        // 选择的合同列表
        contracList: [],
        contractListShow: false,
        // 上传的附件列表
        annexList: [],
        // 初始模版id, 禁止操作
        initTemplateIds: '',
      }
    },
    computed: {
      ...mapState('publishModel', {
        templateData: state => state.templateData,
      }),
      /** 已选择的模版id */
      templateFileIds() {
        if (!this.templateData.fileList.length) return []
        return this.templateData.fileList.map(item => item.templateId)
      },
    },
    mounted() {},
    methods: {
      ...mapMutations('publishModel', {
        setTemplateData: 'setTemplateData',
      }),
      /** 删除合同模版 */
      deleteContrac(id) {
        this.setTemplateData({
          fileList: this.templateData.fileList.filter(
            item => item.templateId !== id
          ),
        })
      },

      /** 删除附件文件 */
      deleteFile(id) {
        this.annexList = this.annexList.filter(item => item.radomId !== id)
      },

      /** 选择变量模版合同 */
      async contractConfirm(data) {
        if (data.length > 20) {
          this.$toast('最多只能选择20个变量模版')
          return
        }
        // 去重
        const uniqueArray = data.filter(
          item => !this.templateFileIds.includes(item)
        )
        this.contractListShow = false
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        try {
          const list = await Promise.all(
            uniqueArray.map(item =>
              publishApis.getTemplateFileDetail({
                templateId: item,
              })
            )
          )
          list.forEach(item => {
            item.fileIndex = getRandom(13)
          })
          this.setTemplateData({
            fileList: [...this.templateData.fileList, ...list],
          })
        } catch (error) {
          console.error(error)
        } finally {
          this.$toast.clear()
        }
      },

      /** 文件上传 */
      async fileUpload(e) {
        try {
          this.$toast.loading({
            message: '上传中',
            forbidClick: false,
            duration: 0,
          })
          const file = e.target.files[0]
          const randomKey = generateRandomKey(8, false)
          if (file) {
            const { name: fileName, size: fileSize } = file
            const { objectFormat, objectBase64, size } = await getFileData(file)
            if (size > 50 * 1024 * 1024) {
              this.$toast('文件大小不能超过50M')
              return
            }
            if (!objectFormat) {
              this.$toast(
                '只支持 .png .jpg .jpeg .doc .docx .xls .xlsx格式的文件'
              )
              return
            }
            const data = await uploadFile(
              { objectBase64, objectFormat },
              file,
              randomKey
            )
            if (!data) {
              return
            }
            console.log(data)
            this.annexList.push(data)
          }
        } catch (error) {
          console.error(error)
          this.$toast('上传失败')
        } finally {
          this.$toast.clear()
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .card-handle {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .txt {
      margin-left: 4px;
      color: #1676ff;
      font-weight: 500;
    }
  }

  .append-title-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 16px 0;
    border-bottom: 1px solid #eeeeee;

    .upload {
      display: flex;
      align-items: center;
      position: relative;
    }
    .txt {
      margin-left: 4px;
      color: #1676ff;
      font-weight: 500;
    }
    .upload-file-handle {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
    }
  }
</style>
