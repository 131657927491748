<template>
  <van-popup
    v-model="visible"
    position="bottom"
    get-container="body"
    safe-area-inset-bottom
  >
    <van-picker
      title="人员选择"
      show-toolbar
      :columns="list"
      @confirm="confirm"
      @cancel="close"
    />
  </van-popup>
</template>
<script>
  /**
   * @description - 人员选择
   * @param {boolean} show - 是否显示
   * @param {Object} list - 列表: {text: String, value: Number}
   * @param {function} confirm - 确定
   * @param {function} cancel - 取消
   */
  export default {
    components: {},
    props: ['show', 'list'],
    data() {
      return {
        personnelshow: false,
        personnelList: [],
      }
    },
    computed: {
      visible: {
        get() {
          return this.show
        },
        set(val) {
          this.$emit('update:show', val)
        },
      },
    },
    created() {},
    mounted() {},

    methods: {
      confirm(val) {
        this.$emit('confirm', val)
        this.$emit('update:show', false)
      },

      close() {
        this.$emit('update:show', false)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
