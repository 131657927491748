import { get, post, qspost } from './axios'

const publishApis = {
  /**
   * 获取模版详情
   * @param {Object} param
   * @param {String} param.templateId 模版id
   */
  getTemplateFileDetail(param) {
    return get('/core/templateFile/getTemplateFileDetail', param)
  },

  /**
   * 获取公司法人信息
   */
  getComInfo(param) {
    return get('/user/companyInfo/getComInfo')
  },

  /** 
   * 获取应用详情
   */
  getTemplateBusiness(params = {}) {
    return get('/core/templateBusiness/getTemplateBusiness', params)
  },
}

export default publishApis
