<template>
  <Card title="签署方">
    <template slot="title">
      <span class="card-handle">
        <van-icon name="question-o" color="#cccccc" @click="help" />

        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="signOrderMap"
          @select="selectSignOrder"
          placement="bottom-end"
        >
          <template #reference>
            <span class="txt">
              {{ templateData.signOrderFlag ? '顺序签' : '无序签' }}
            </span>
          </template>
        </van-popover>
      </span>
    </template>

    <Draggable
      v-model="partnerInfo"
      :disabled="partnerInfo.length <= 1 || !templateData.signOrderFlag"
      animation="300"
      :delay="100"
    >
      <template v-for="item in partnerInfo">
        <!-- 内部参与方 -->
        <Inside
          v-if="item.partnerRole === 0"
          :partener="item"
          :key="item.guid"
          @change="data => processChange(data, item.guid)"
          @delCard="delPartnerInfo"
        />

        <!-- 外部个人 -->
        <Individual
          v-else-if="item.partnerRole === 1"
          :partener="item"
          :key="item.guid"
          @change="data => processChange(data, item.guid)"
          @delCard="delPartnerInfo"
        />

        <!-- 外部企业 -->
        <Company
          v-else-if="item.partnerRole === 2"
          :partener="item"
          :key="item.guid"
          @change="data => processChange(data, item.guid)"
          @delCard="delPartnerInfo"
        />
      </template>
    </Draggable>

    <div class="process-card" v-if="partnerInfo.length < 20">
      <div class="add-participants">
        <div class="name">添加签署参与方</div>
        <div class="group-btn">
          <van-button
            icon="plus"
            size="small"
            color="#ECF4FF"
            @click="addPartner(0)"
          >
            发起方
          </van-button>
          <van-button
            icon="plus"
            size="small"
            color="#ECF4FF"
            @click="addPartner(1)"
          >
            外部个人
          </van-button>
          <van-button
            icon="plus"
            size="small"
            color="#ECF4FF"
            @click="addPartner(2)"
          >
            外部企业
          </van-button>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
  import { mapState, mapMutations } from 'vuex'
  import Draggable from 'vuedraggable'
  import { getRandom } from '@/utils/index'
  import cloneDeep from 'lodash.clonedeep'
  import Card from './Card.vue'
  import Inside from './Inside.vue'
  import Individual from './Individual.vue'
  import Company from './Company.vue'

  const initPartnerInfo = {
    isAllFile: true,
    // 指定的id（印章、人、企业或角色）
    assignId: null,
    assignName: '',
    // 指定方0-现在指定 1-由发起人指定
    assignPart: null,
    // 指定的参与方索引
    assignPartnerIndex: null,
    // 指定参与方类型（关系角色） 0.上级 1.下级
    assignPartnerType: null,
    // 参与方指定类型：0.指定人 1.指定章 2.企业角色 3.关系角色 4.法人 5.发起人
    assignType: 0,
    // false.单份 true.批量
    batchSign: 0,
    // 参与方自定义名称
    customName: null,
    // 参与方默认名称
    defaultName: null,
    // 经办人姓名
    handlerName: null,
    // 经办人手机号
    handlerTel: null,
    // 流程id(修改时不为空)
    partnerId: null,
    // 参与方索引
    partnerIndex: null,
    // 顺序
    partnerOrder: null,
    // 参与方角色：0.内部参与方 1.外部个人 2.外部企业
    partnerRole: null,
    // 当前参与方的 通知消息类型
    messageType: ['2', '4', '6'], // defaultPartnerNoticeMessageType,
    // 当前参与方的 消息通知方式
    noticeWay: [0], // defaultPartnerNoticeWay,
    // 当前参与方是否需要被通知
    checkFlag: 1,
    // 参与方文件操作列表
    partnerSubList: [],
    assignIdList: [],
    signerList: [],
  }

  export default {
    components: { Card, Inside, Individual, Company, Draggable },
    data() {
      return {
        signOrderMap: [
          { text: '无序签', value: 0 },
          { text: '顺序签', value: 1 },
        ],
        showPopover: false,
      }
    },
    computed: {
      ...mapState({
        /** 是否为企业用户 */
        isComUser: state => state.user.isComUser,
        /** 用户信息 */
        userInfo: state => state.user.userInfo,
        templateData: state => state.publishModel.templateData,
      }),
      partnerInfo: {
        get() {
          return this.$store.state.publishModel.partnerInfo
        },
        set(val) {
          val.forEach((item, index) => {
            item.defaultName = `参与方${index + 1}`
          })
          this.updatePartnerInfo([...val])
        },
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      ...mapMutations('publishModel', [
        'setPartnerInfo',
        'delPartnerInfo',
        'updatePartnerInfo',
      ]),

      init() {
        if(this.partnerInfo.length) return
        this.addPartner(0)
      },

      /** 添加参与方: 0:内部参与方 1: 外部个人 2: 外部企业 */
      addPartner(type) {
        const partner = cloneDeep(initPartnerInfo)
        partner.partnerIndex = getRandom(13)
        partner.guid = getRandom(11)
        partner.partnerRole = type
        partner.assignType = 0
        partner.defaultName = `参与方${this.partnerInfo.length + 1}`

        switch (type) {
          case 0:
            partner.checkFlag = 0
            partner.messageType = []
            partner.noticeWay = []
            partner.signerList = []
            partner.customName = '发起方'
            if (this.isComUser) {
              partner.partnerSubList = this.createInitialPartnerSubList([4])
              partner.assignType = 1
            } else {
              partner.assignId = this.userInfo.userId
            }
            break
          case 1:
          case 2:
            partner.signerList = this.createInitialSigner()
            partner.partnerSubList = this.createInitialPartnerSubList(
              type === 1 ? [1] : [4]
            )
            partner.customName = '接收方'
            partner.noticeWay = this.templateData.noticeWay
            break
          default:
            break
        }
        this.setPartnerInfo(partner)
      },

      /** 添加操作方式 */
      createInitialPartnerSubList(operateList) {
        return [
          {
            fileIndex: '-1',
            fileKey: null,
            operateList,
          },
        ]
      },

      /** 添加初始签署人员 */
      createInitialSigner() {
        return [
          {
            comId: null,
            comName: null,
            handlerName: null,
            handlerTel: null,
            userId: null,
          },
        ]
      },

      processChange(data, key) {
        const find = this.partnerInfo.find(item => item.guid === key)
        Object.assign(find, data)
      },
      help() {
        this.$dialog
          .alert({
            title: '说明',
            message:
              '【无序签】对签署顺序没有要求，多方可以同时签署 \n 【顺序签】希望指定签署前后顺序，A->B->C',
          })
          .then(() => {})
      },

      selectSignOrder(val) {
        this.templateData.signOrderFlag = val.value
      },
    },
  }
</script>

<style lang="less" scoped>
  .card-handle {
    font-size: 13px;

    .txt {
      padding-left: 4px;
      position: relative;
      font-weight: 400;

      &::after {
        content: '';
        position: absolute;
        top: 0.16rem;
        right: -0.32rem;
        border-left: 0.08rem solid transparent;
        border-right: 0.08rem solid transparent;
        border-color: transparent;
        border-top: 0.13333rem solid #434751;
      }
    }
  }
  .process-card {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #f6f8fb;
    margin-top: 14px;

    .add-participants {
      padding: 16px 12px;
      .name {
        font-size: 15px;
        color: #111a34;
      }
      .group-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
      }
      .van-button__text,
      .van-button__content {
        color: #0075ff;
        font-weight: bold;
      }
      .van-button__content {
        padding: 0 8px;
      }
    }
  }
</style>
