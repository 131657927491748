<template>
  <div class="files-list">
    <img class="icon" v-if="logo" :src="logo" alt="" />
    <img
      class="icon"
      v-else
      :src="require(`@/assets/imgs/signFile/pdf.svg`)"
      alt=""
    />

    <div class="file-info">
      <div class="name van-ellipsis">{{ name }}</div>
      <div class="size" v-if="size">{{ (size / 1024).toFixed(2) }}KB</div>
    </div>
    <img
      v-if="delIcon"
      class="delete-icon"
      src="@/assets/imgs/编组 17@2x.png"
      @click.stop="deleteFile"
    />
  </div>
</template>
<script>
  export default {
    components: {},
    props: ['name', 'delIcon', 'size', 'logo'],
    data() {
      return {}
    },
    mounted() {},
    methods: {
      // 删除文件
      deleteFile() {
        this.$emit('deleteFile')
      },
    },
  }
</script>

<style lang="less" scoped>
  .files-list {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #eeeeee;
    background: #f8f9fa;
    border-radius: 4px;
    position: relative;
    margin-top: 10px;
    .icon {
      width: 32px;
      height: 32px;
    }

    .file-info {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      margin-left: 12px;
      color: #1f2023;
      width: calc(100% - 40px);
    }
    .name {
      width: 100%;
      overflow: hidden;
    }
    .size {
      color: #747980;
      font-size: 12px;
    }

    .delete-icon {
      position: absolute;
      right: -1px;
      top: 0;
      width: 16px;
      height: 14px;
    }
  }
</style>
